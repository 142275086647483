import * as React from "react";
import { useCallback, useEffect } from "react";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";
import { Typography } from '@mui/material';

const animationSlideInBottom = keyframes`
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }    
  `;
const Heading = styled(Typography)`
&.MuiTypography-h2 {
  // margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--primary-highlight);
  }
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-medium-light-size);
  line-height: 150%;
  font-weight: 300;
  font-family: var(--text-regular-light);
  color: var(--border-primary);
`;
const SectionTitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
  color: var(--primary-highlight);
`;
const FrameIcon = styled.img`
  width: 3.375rem;
  position: relative;
  height: 3.375rem;
  overflow: hidden;
  flex-shrink: 0;
`;
const SubheadingOne = styled(Typography)`
&.MuiTypography-h3 {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 140%;
  text-transform: uppercase;
  }
`;
const Text2 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
`;
const ListItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const List = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0rem;
  gap: var(--gap-5xl);
  font-size: var(--heading-desktop-h5-size);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const Updates1 = styled.div`
  position: relative;
  line-height: 150%;
`;
const Button1 = styled.div`
  border: 1px solid var(--border-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  cursor: pointer;
`;
const Button2 = styled.div`
  background-color: var(--border-primary);
  border: 1px solid var(--border-primary);
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-xl);
  color: var(--text-alternate);
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-11xl) 0rem 0rem;
  gap: var(--gap-base);
  font-size: var(--text-regular-light-size);
  font-family: var(--text-regular-light);
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 35.063rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Text3 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-3xs);
`;
const Card = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  font-size: var(--text-regular-light-size);
  font-family: var(--text-regular-light);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Container = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-61xl);
  opacity: 0;
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationSlideInBottom};
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const UpdatesRoot = styled.section`
  align-self: stretch;
  background-color: var(--color-gray-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  text-align: left;
  font-size: var(--heading-desktop-h3-size);
  color: var(--border-primary);
  font-family: var(--heading-desktop-h4);
`;

const Updates = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  const onButtonContainerClick = useCallback(() => {
    navigate("/the-mankayan-project");
  }, []);

  return (
    <UpdatesRoot>
      <Container data-animate-on-scroll>
        <Content>
          <SectionTitle>
            <Heading variant='h2'>
              The Latest Updates on the Mankayan Project's Progress and
              Exploration Activities
            </Heading>
            <Text1>
              Discover the recent developments and ongoing exploration
              activities of the Mankayan Project, one of the largest undeveloped
              copper-gold porphyry projects in the world.
            </Text1>
          </SectionTitle>
          <List>
            <ListItem>
              <FrameIcon alt="" src="/frame.svg" />
              <SubheadingOne variant='h3'>Recent Updates</SubheadingOne>
              <Text2>
                Stay informed with the latest progress, milestones, and
                achievements at the Mankayan Project.
              </Text2>
            </ListItem>
            <ListItem>
              <FrameIcon alt="" src="/frame1.svg" />
              <SubheadingOne variant='h3'>Exploration Activities</SubheadingOne>
              <Text2>
                Learn about the ongoing exploration activities and potential
                value at the Mankayan Project.
              </Text2>
            </ListItem>
          </List>
          <Actions>
            <Button1 onClick={onButtonContainerClick}>
              <Updates1>Updates</Updates1>
            </Button1>
            <Button2>
              <Updates1>Activities</Updates1>
            </Button2>
          </Actions>
        </Content>
        <Card>
          <PlaceholderImageIcon alt="" src="/placeholder-image37@2x.png" />
          <Content1>
            <Text3>
              Mankayan (Guinaoang) deposit and proximal deposits. Looking North.
            </Text3>
          </Content1>
        </Card>
      </Container>
    </UpdatesRoot>
  );
};

export default Updates;
