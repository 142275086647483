import * as React from "react";
import { useCallback, useEffect } from "react";
import { Button, Icon, Typography } from "@mui/material";
import { navigate } from "gatsby";
import styled, { keyframes } from "styled-components";

const Jorc2012Mineral = styled.p`
  margin: 0;
`;
const Subheading = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
`;
const Heading = styled(Typography)`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--heading-desktop-h2-size);
  line-height: 120%;
  text-transform: uppercase;
  font-weight: 400;
  font-family: var(--heading-desktop-h4);
`;
const Column = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const TheProjectIs = styled.p`
  margin-block-start: 0;
  margin-block-end: 16px;
`;
const Text1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  font-weight: 300;
`;
const Button1 = styled(Button)``;
const Button2 = styled(Button)`
  cursor: pointer;
`;
const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) 0rem 0rem;
  gap: var(--gap-5xl);
`;
const Column1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  color: var(--text-alternate);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Content = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-61xl);
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const animationFadeInBottom = keyframes`
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
  `;
const Copper = styled.p`
  margin: 0;
  font-size: var(--heading-desktop-h4-size);
  line-height: 130%;
  text-transform: uppercase;
`;
const Indicated = styled.span``;
const Mt = styled.span`
  font-size: var(--text-regular-caps-size);
`;
const Indicated23Mt = styled.span`
  line-height: 150%;
  font-weight: 300;
`;
const Indicated23Mt1 = styled.p`
  margin: 0;
  font-family: var(--text-regular-light);
`;
const Text2 = styled.div`
  align-self: stretch;
  position: relative;
`;
const Text3 = styled(Typography)`
&.MuiTypography-h2 {
  align-self: stretch;
  position: relative;
  line-height: 120%;
      font-weight: 600;
    font-size: 7rem;
  }
`;
const Divider = styled.div`
  align-self: stretch;
  position: relative;
  border-top: 1px solid var(--primary-highlight);
  box-sizing: border-box;
  height: 0.063rem;
`;
const Text4 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--text-regular-light-size);
  line-height: 150%;
  font-family: var(--text-regular-light);
`;
const Content1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  text-align: right;
  font-size: var(--font-size-61xl);
  color: var(--primary-highlight);
  font-family: var(--text-regular-normal);
`;
const Stat = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-13xl);
  background-image: url("/stat@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const PlaceholderImageIcon = styled.img`
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 17.313rem;
  flex-shrink: 0;
  object-fit: cover;
`;
const Stat1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  gap: var(--gap-29xl);
  background-image: url("/stat1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;
const Column2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`;
const Stat2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-13xl);
  gap: var(--gap-29xl);
  background-image: url("/stat2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;
const Stats = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  opacity: 0;
  font-size: var(--text-medium-light-size);
  color: var(--text-alternate);
  font-family: var(--heading-desktop-h4);
  &.animate {
    animation: 1s ease 0s 1 normal forwards ${animationFadeInBottom};
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;
const StatsRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-93xl) var(--padding-45xl);
  gap: var(--gap-61xl);
  text-align: left;
  font-size: var(--text-regular-light-size);
  color: var(--primary-highlight);
  font-family: var(--text-regular-light);
  @media screen and (max-width: 960px) {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
`;

const StatsOne = () => {
  const onButton1Click = useCallback(() => {
    navigate("/investor-updates");
  }, []);

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add("animate");
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);
  return (
    <StatsRoot>
      <Content>
        <Column>
          <Subheading>
            <Jorc2012Mineral>
              JORC 2012 MINERAL RESOURCE ESTIMATE
            </Jorc2012Mineral>
            <Jorc2012Mineral>(0.25% CuEq cut-off)</Jorc2012Mineral>
          </Subheading>
          <Heading variant='h2'>MANKAYAN – AN OVERVIEW</Heading>
        </Column>
        <Column1>
          <Text1>
            <TheProjectIs>{`The project is situated in Northern Luzon, approximately 340 kilometres away from Manila, making it easily accessible for logistical purposes. It is strategically positioned near the heart of the Mankayan Mineral District, a region known for its rich mineral deposits and mining activities. `}</TheProjectIs>
            <TheProjectIs>{`The combined JORC 2012 mineral resource estimate for the project stands at an impressive 793 million tonnes which includes significant quantities of copper, gold and silver. Specifically the resource contains 2.8 million tonnes of copper 9.7 million ounces of gold and 21 million ounces of silver all at a cut-off grade exceeding 0.25% copper equivalent. Within the global resource are higher grade zones going >0.8%Cu and 1.0%Cu. `}</TheProjectIs>
            <TheProjectIs>{`This substantial resource base has been delineated through the completion of over 56,000 meters of diamond drilling providing detailed geological information and supporting the robustness of the mineral estimates. `}</TheProjectIs>
            <Jorc2012Mineral>
              The extensive drilling program has helped in defining the
              mineralization zones, understanding the ore body geometry and
              enhancing the overall confidence in the resource calculations.
            </Jorc2012Mineral>
          </Text1>
          <Actions>
            <Button1
              disableElevation={true}
              color="primary"
              variant="contained"
              
            >
              More Details
            </Button1>
            <Button2
              disableElevation={true}
              color="primary"
              variant="text"
              endIcon={<Icon>arrow_forward_ios_sharp</Icon>}
              
              onClick={onButton1Click}
            >
              Investor Info
            </Button2>
          </Actions>
        </Column1>
      </Content>
      <Stats data-animate-on-scroll>
        <Stat>
          <Text2>
            <Copper>Copper</Copper>
            <Indicated23Mt1>
              <Indicated23Mt>
                <Indicated>{`Indicated : 2.3 `}</Indicated>
                <Mt>Mt</Mt>
              </Indicated23Mt>
            </Indicated23Mt1>
            <Jorc2012Mineral>
              <Indicated23Mt>
                <Indicated>{`Inferred: 0.5 `}</Indicated>
                <Mt>Mt</Mt>
              </Indicated23Mt>
            </Jorc2012Mineral>
          </Text2>
          <Content1>
            <Text3 variant='h2'>Cu 2.8</Text3>
            <Divider />
            <Text4>TOTAL (Mt)</Text4>
          </Content1>
        </Stat>
        <Column2>
          <PlaceholderImageIcon alt="" src="/placeholder-image30@2x.png" />
          <Stat1>
            <Text2>
              <Copper>GOLD</Copper>
              <Indicated23Mt1>
                <Indicated23Mt>
                  <Indicated>{`Indicated : 8.2 `}</Indicated>
                  <Mt>Moz</Mt>
                </Indicated23Mt>
              </Indicated23Mt1>
              <Jorc2012Mineral>
                <Indicated23Mt>
                  <Indicated>{`Inferred: 1.5 `}</Indicated>
                  <Mt>Moz</Mt>
                </Indicated23Mt>
              </Jorc2012Mineral>
            </Text2>
            <Content1>
              <Text3 variant='h2'>Au 9.7</Text3>
              <Divider />
              <Text4>TOTAL (Moz)</Text4>
            </Content1>
          </Stat1>
        </Column2>
        <Column2>
          <Stat2>
            <Text2>
              <Copper>Silver</Copper>
              <Indicated23Mt1>
                <Indicated23Mt>
                  <Indicated>{`Indicated : 18 `}</Indicated>
                  <Mt>Moz</Mt>
                </Indicated23Mt>
              </Indicated23Mt1>
              <Jorc2012Mineral>
                <Indicated23Mt>
                  <Indicated>{`Inferred: 3 `}</Indicated>
                  <Mt>Moz</Mt>
                </Indicated23Mt>
              </Jorc2012Mineral>
            </Text2>
            <Content1>
              <Text3 variant='h2'>Ag 21</Text3>
              <Divider />
              <Text4>TOTAL (Moz)</Text4>
            </Content1>
          </Stat2>
          <PlaceholderImageIcon alt="" src="/placeholder-image31@2x.png" />
        </Column2>
      </Stats>
    </StatsRoot>
  );
};

export default StatsOne;
