import * as React from "react";
import { useCallback } from "react";
import { navigate } from "gatsby";

import SectionHero from "../components/section-hero";
import Intro1Section from "../components/intro1-section";
import StatsOne from "../components/stats-one";
import DiscoverCTA from"../components/discover-c-t-a";
import Updates1 from "../components/updates1";
import Updates from "../components/updates";
import Gallery from "../components/gallery";
import Footer from '../components/footer1';
import styled from "styled-components";

const HomeRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: var(--primary-main);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Home = () => {

  return (
    <HomeRoot>
      <SectionHero />
      <Intro1Section />
      <StatsOne />
      <DiscoverCTA />
      <Updates1 />
      <Updates />
      <Gallery />
      <Footer />
    </HomeRoot>
  );
};

export default Home;
